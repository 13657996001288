/* global angular */
(function () {
    angular
        .module('documentation')
        .component('documentationProjectObjectTable', {
            controller: controller,
            templateUrl: 'partials/documentation-project-object-table.html',
            controllerAs: 'vm',
            bindings: {

            }
        });

    function controller() {
    }

})();
